:export {
  errorColor: #ed6262;
  cventBlue: #0099e0;
  royalBlue: #006d96;
  primaryGrey: #5f7d8c;
  green: #1daa42;
  yellow: #eba912;
  pastelRed: #ed6262;
  orange: #ff9100;
  minimalGrey: #f4f8f9;
  lightGrey: #e9eded;
  mediumGrey: #cad0d3;
  grey: #9fb1b9;
  errorRed: #cc000a;
}
.outerContainer {
  margin: 0 auto;
  width: 6em;
  height: 6em;
  padding: 1.5rem;
  background: #f4f8f9;
  border-radius: 50%;
}
.container {
  position: relative;
  width: 3em;
  height: 3em;
  margin: 0 auto;
  transform: translateZ(0) rotate(0.1);
}
.hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
}
.circle {
  border-radius: 50%;
  border-style: solid;
  position: absolute;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
}
.outerCircle {
  composes: circle;
  width: 3em;
  height: 3em;
  top: 0;
  left: 0;
  animation-duration: 3000ms;
}
.middleCircle {
  composes: circle;
  width: 2em;
  height: 2em;
  top: 0.5em;
  left: 0.5em;
  animation-duration: 2000ms;
}
.innerCircle {
  composes: circle;
  width: 1em;
  height: 1em;
  top: 1em;
  left: 1em;
  animation-duration: 1500ms;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.circle {
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
}
.outerCircle {
  composes: circle;
  border-color: rgba(0, 0, 0, 0.9);
  border-bottom-color: transparent;
}
.middleCircle {
  composes: circle;
  border-color: rgba(0, 0, 0, 0.9);
  border-left-color: transparent;
}
.innerCircle {
  composes: circle;
  border-color: rgba(0, 0, 0, 0.9);
  border-top-color: transparent;
}

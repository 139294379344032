@import './Variables.less';

.tagContainer {
  padding-top: 1.14em;
}

.tagPill {
  color: @defaultTagColor;
  background-color: @defaultTagBackgroundColor;
  font-size: 0.875em;
  border-radius: 1.2em;
  padding: 0.125em 0.75em;
  margin-right: 0.5em;
  line-height: 1;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.tagDot {
  background-color: @defaultTagBackgroundColor;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  margin-right: 0.2em;
  display: inline-block;
  &:first-child {
    margin-left: 0.1em;
  }
}

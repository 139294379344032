@import "./Variables.less";
@import "~nucleus-core/less/MediaQuery.less";

.dragContainer {
  background: #FFF;
  border: 0;
  width: @dialogWidth;
  @media @medium {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    display: inline-block;
    overflow-y: scroll;
    top: 0px !important;
    left: 0px !important;
  }
}

.content {
  padding: 1.5em 1.2em;
  font-size: @baseFont;
}

.button {
  position: absolute;
  top: .2em;
  right: .2em;
  border: 0;
  background: @closeBackground;
  padding: 0;
  font-size: 1.25em;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
  color: @closeIconColor;
  cursor: pointer;
}

.overlay {
  background: @overlayColor;
  opacity: @overlayOpacity;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: @overlayIndex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@import "~cvent-event-calendar/lib/CalendarView/styles/Variables.less";

.fieldRow {
  background: none;
}

.title {
  font-size: 1.4em;
  line-height: 1.2;
  padding-right: 1em;
  color: @textColor;
  font-weight:bold;
  word-wrap: break-word;
  h5 {
    margin: 0;
    padding: 0;
  }
}

.eventDate {
  font-size: 1em;
  color: @textColor;
  margin-top: .8em;
  line-height: 1.4;
}

.locationWrapper {
  width: 100%;
  float: left;
}

.location {
  font-size: 1em;
  margin-top: .8em;
  line-height: 1.4;
  color: @textColor;
  line-height: 1.4;
  display: inline-block;
  width: 100%;
}

.eventLocation {
  display: block;
}

.displayIcon {
  position: relative;
  padding-left: 1.4em;
}

.smallIcon {
  position: absolute;
  top: -.05em;
  left: 0;
  font-size: 1.14em;
  color: @iconColor;
  line-height: 1.25;
}

.dateIconWrapper {
  .displayIcon;
  .dateIcon {
    .smallIcon;
  }
}

.locationIconWrapper {
  .displayIcon;
  .locationIcon {
    .smallIcon;
  }
}

.description,
.html,
.custom,
.date {
  font-size: 1em;
  line-height: 1.5;
  color: @textColor;
  word-wrap: break-word;
  h6 {
    font-size: 1.14em;
    line-height: 1.25em;
    font-weight: bold;
    color: @textColor;
    margin: 1.25em 0 .55em 0;
  }
  ul, ol {
    padding-left: 1.2em;
  }
}

.loadingText {
  text-align: center;
  margin: 0 auto;
}

@import '~@cvent/fonts/css/fonts.css';
@import '~nucleus-core/less/Reset.less';

.wrapper,
.container {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.emptyMessage {
  font-size: 14px;
  color: #303030;
  text-align: center;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

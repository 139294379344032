@import "~cvent-event-calendar/lib/CalendarView/styles/Variables.less";

.button {
  background: @modalButtonBgColor;
  color: @buttonTextColor;
  font-size: 1.28em;
  line-height: 1.2;
  min-width: 50%;
  border-radius: 3px;
  border-width: 0;
  padding: .65em 1.5em;
  font-weight: normal;
  margin: 1em 0 .2em 0;
  cursor: pointer;
  @media only screen and (max-width : 414px) {
    min-width: 100%;
  }
}
